import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export const get_user_payment = createAsyncThunk(
    'payment/get_user_payment',
    async (userId) => {
        try {
            const { data: user_payment } = await axios.get(`http://localhost:5000/get-user-payment/${userId}`)
            return user_payment
        } catch (error) {
            console.log(error)
        }
    }
)
export const get_user_bill_details = createAsyncThunk(
    'payment/get_user_bill_details',
    async (billId) => {
        try {
            const { data: bill_details } = await axios.get(`http://localhost:5000/get-user-bill-details/${billId}`)
            return bill_details
        } catch (error) {
            console.log(error)
        }
    }
)
export const userPaymentSlcie = createSlice({
    name: 'user_payment',
    initialState: {
        user_payment_list: [],
        user_bill: {},
        successMessage: "",
        errorMessage: ""
    },
    reducers: {

    },
    extraReducers: {
        [get_user_payment.fulfilled]: (state, action) => {
            state.user_payment_list = action.payload.user_payment
        },
        [get_user_bill_details.fulfilled]: (state, action) => {
            state.user_bill = action.payload.bill_details
        }
    }
})
export default userPaymentSlcie.reducer