export const editData = {
    id: 2,
    division: 'Dhaka',
    district: 'Dhaka',
    region: 'Savar',
    address: 'Pabna,sodor #454',
    lat: 24.016,
    lng: 90.4908,
    category: 'Flat',
    month: '2022-12',
    gender: 'Male',
    price: 2323,
    title: 'Need Flat',
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has",
    bedrooms: 3,
    bathrooms: 2,
    floor_info: '20 ft',
    food_details: "making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover",
    religion: 'Muslim',
    near_university: 'DU',
    profession: 'Student',
    interest: ['Job preparation', 'BCS preparation', 'Politics', 'Reading books', "Travel"],
    images: [
        {
            url: 'http://localhost:3000/images/room-3.jpg'
        },
        {
            url: 'http://localhost:3000/images/room-1.jpeg'
        }, {
            url: 'http://localhost:3000/images/room-4.jpg'
        }
    ]

}