import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { languageData } from '../utils/language'
import axios from 'axios'
import { base_url } from '../utils/config'

export const get_customers = createAsyncThunk(
    'language/customers',
    async (_, { fulfillWithValue, rejectWithValue, getState }) => {
        const { token, } = getState().auth

        try {
            const { data } = await axios.get(`${base_url}/messbook/api/customers`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
           return fulfillWithValue(data)
        } catch (error) {
            console.log(error)
        }
    }
)

export const listingSlice = createSlice({
    name: 'language',
    initialState: {
        language: languageData.english,
        customers: []
    },
    reducers: {
        languageHandle: (state, action) => {
            state.language = action.payload
        }
    },
    extraReducers : {
        [get_customers.fulfilled] : (state,{payload})=>{
            state.customers = payload.customers
        }
    }
})
export const { languageHandle } = listingSlice.actions
export default listingSlice.reducer