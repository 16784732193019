export const languageData = {
    bangla: {
        type: 'Bangla',
        listingAdd: {
            title: "বাসার লোকেশন",
            division: "বিভাগ",
            district: 'জেলা সিলেকশন',
            thana: 'এলাকা সিলেকশন',
            house_address: ' বাসার ঠিকানা'
        }
    },
    english: {
        type: 'English',
        listingAdd: {
            title: "Location",
            division: "Division",
            district: 'District',
            thana: 'Region',
            house_address: 'House Location'
        }
    }

}
