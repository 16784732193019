import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export const get_users = createAsyncThunk(
    'mess/get_users',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data: { users } } = await axios.get('http://localhost:5000/get-users')
            return fulfillWithValue(users)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const mess_create = createAsyncThunk(
    'mess/mess_create',
    async (messData) => {
        try {
            const { data: { message } } = await axios.post('http://localhost:5000/mess-create', messData)
            return message
        } catch (error) {
            console.log(error)
        }
    }
)


export const get_messes = createAsyncThunk(
    'mess/get_messes',
    async (ownerId, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axios.get(`http://localhost:5000/get-messes/${ownerId}`)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)

export const get_messDetails = createAsyncThunk(
    'mess/get_messDetails',
    async (messId) => {
        try {
            const { data: { mess } } = await axios.get(`http://localhost:5000/get-mess-details/${messId}`)
            return mess
        } catch (error) {
            console.log(error)
        }
    }
)


export const messSlice = createSlice({
    name: 'mess',
    initialState: {
        successMessage: '',
        errorMessage: '',
        users: [],
        messes: [],
        messData: {},
        loader: false
    },
    reducers: {

    },
    extraReducers: {
        [get_users.fulfilled]: (state, action) => {
            state.users = action.payload
        },
        [mess_create.pending]: (state, action) => {
            state.loader = true
        },
        [mess_create.fulfilled]: (state, action) => {
            state.loader = false
            state.successMessage = action.payload
        },
        [get_messes.fulfilled]: (state, action) => {
            console.log(action.payload)
            state.messes = action.payload
        },
        [get_messDetails.fulfilled]: (state, action) => {
            state.messData = action.payload
        }
    }
})
export default messSlice.reducer