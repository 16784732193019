import axios from 'axios';
import cookie from 'js-cookie'
import { subDomain } from '../../utils/utils'
import { api } from '../../api/api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const loginWithEmail = createAsyncThunk(
    'auth/loginWithEmail',
    async (info) => {
        try {
            const url = 'https://api.messbook.com.bd/user/auth/login'
            const data = { username: 'arafatkn@gmail.com', password: '111111' }
            const response = await axios.post(url, data);
            console.log(response)
        } catch (error) {
            console.log(error.response.data)
        }
    }
)

export const getUserInfo = createAsyncThunk(
    'auth/getUserInfo',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('https://apiv2.messbook.com.bd/user/profile');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const authReducer = createSlice({
    name: 'auth',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        userInfo: "",
        //ability: cookie.get('access_ability', { domain: subDomain }) ? JSON.parse(cookie.get('access_ability', { domain: subDomain })) : "",
        ability: localStorage.getItem('access_ability') ? JSON.parse(localStorage.getItem('access_ability')) : '',
        //token: cookie.get('messbookToken'),
        token: localStorage.getItem('messbookToken') ? localStorage.getItem('messbookToken') : ''
    },
    reducers: {
        setAbility: (state, { payload }) => {
            state.token = payload.token
            state.ability = {
                type: payload.type,
                panel: payload.panel
            }
        },
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
    },
    extraReducers: {
        [loginWithEmail.pending]: (state, _) => {
            state.loader = true
        },
        [loginWithEmail.fulfilled]: (state, action) => {
            state.userInfo = action.payload
            state.loader = false
        },
        [getUserInfo.fulfilled]: (state, action) => {
            state.userInfo = action.payload
        }
    }
})
export const { setAbility } = authReducer.actions
export default authReducer.reducer