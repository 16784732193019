import toletReducer from '../views/owner/tolet/store/toletReducer';
import profileReducer from '../views/owner/Profile/store/profileReducer';
import authReducer from './reducers/authReducer';
import languageReducer from './languageReducer';
import paymentReducer from '../views/owner/payment/store/paymentReducer';
import userPaymentReducer from '../views/finder/payment/store/userPaymentReducer';
//import chatReducer from '../views/chat/store/chatReducer';
import messReducer from '../views/owner/mess/store/messReducer';
import userReducer from './reducers/userReducer';
import chatReducer from './reducers/chatReducer';
const rootReducer = {
    toletReducer,
    language: languageReducer,
    profileReducer,
    auth: authReducer,
    payment: paymentReducer,
    user_payment: userPaymentReducer,
    mess: messReducer,
    user: userReducer,
    chat: chatReducer
}
export default rootReducer;