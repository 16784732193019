import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api/api'

export const get_wishlist = createAsyncThunk(
    'tolet/get_wishlist',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await api.get('/user/wishlist')
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loader: false,
        wishlists: []
    },
    reducers: {

    },
    extraReducers: {
        [get_wishlist.pending]: (state, _) => {
            state.loader = true
        },
        [get_wishlist.fulfilled] : (state,action)=>{
            state.loader = false
            state.wishlists = action.payload
        }
    }
})
export default userSlice.reducer
