import { editData } from '../../../../fakeData/fakeData';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../api/api'
export const add_tolet = createAsyncThunk(
    'tolet/add_tolet',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        const { address, bathrooms, bedrooms, category, description, district, division, floor_info, food_details, gender, image, interest, lat, lng, month, near_university, price, profession, region, religion, title, video_link } = info;
        const formData = new FormData();
        console.log(info)
        formData.append('address', address);
        //formData.append('bathrooms', parseInt(bathrooms));
        //formData.append('bedrooms', parseInt(bedrooms));
        formData.append('type', category);
        formData.append('description', description);
        formData.append('district_id', parseInt(district));
        formData.append('division_id', parseInt(division));
        formData.append('area_id', parseInt(region));
        //formData.append('floor_info', floor_info);
        //formData.append('food_details', food_details);
        for (let i = 0; i < image.length; i++) {
            formData.append('images[]', image[i]);
        }
        formData.append('gender', gender);
        formData.append('usable_date', month)
        //formData.append('interest', interest);
        formData.append('latitude', lat);
        formData.append('longitude', lng);
        //formData.append('near_university', near_university);
        formData.append('rent', parseInt(price));
        //formData.append('profession', profession);
        //formData.append('religion', religion);
        formData.append('title', title);
        //formData.append('video_link', video_link);
        try {
            const { data } = await api.post('/user/tolets', formData);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)


export const update_tolet = createAsyncThunk(
    'tolet/update_tolet',
    async ({ state, toletId }, { rejectWithValue, fulfillWithValue }) => {
        const { address, bathrooms, bedrooms, category, description, district, division, floor_info, food_details, gender, image, interest, lat, lng, month, near_university, price, profession, region, religion, title, video_link } = state;
        const formData = new FormData();
        formData.append('address', address);
        //formData.append('bathrooms', parseInt(bathrooms));
        //formData.append('bedrooms', parseInt(bedrooms));
        formData.append('type', category);
        formData.append('description', description);
        formData.append('district_id', parseInt(district));
        formData.append('division_id', parseInt(division));
        formData.append('area_id', parseInt(region));
        //formData.append('floor_info', floor_info);
        //formData.append('food_details', food_details);
        formData.append('gender', gender);
        formData.append('usable_date', month)
        //formData.append('interest', interest);
        formData.append('latitude', lat);
        formData.append('longitude', lng);
        //formData.append('near_university', near_university);
        formData.append('rent', parseInt(price));
        //formData.append('profession', profession);
        //formData.append('religion', religion);
        formData.append('title', title);
        //formData.append('video_link', video_link);
        formData.append('_method', 'PUT')
        try {
            const { data } = await api.post(`/user/tolets/${toletId}`, formData);
            return fulfillWithValue(data)
        } catch (error) {
            console.log(error.response.data)
            return rejectWithValue(error.response.data)
        }
    }
)

export const get_tolets = createAsyncThunk(
    'tolet/get_tolets',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await api.get('/user/tolets');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

export const get_tolet = createAsyncThunk(
    'tolet/get_tolet',
    async (toletId, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await api.get(`/tolets/${toletId}`);
            return fulfillWithValue(data)
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error)
        }

    }
)

export const delete_image = createAsyncThunk(
    'tolet/delete_image',
    async ({ toletId, imageUrl }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const formData = new FormData()
            formData.append('image_url', imageUrl)
            formData.append('_method', 'DELETE')
            const { data } = await api.post(`/user/tolets/${toletId}/images`, formData);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const add_image = createAsyncThunk(
    'tolet/add_image',
    async ({ toletId, image }, { fulfillWithValue, rejectWithValue }) => {
        const formData = new FormData();
        formData.append('image', image)
        try {
            const { data } = await api.post(`/user/tolets/${toletId}/images`, formData);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const toletSlice = createSlice({
    name: 'tolets',
    initialState: {
        loader: false,
        imageLoader: false,
        successMessage: '',
        errorMessage: '',
        tolets: [],
        tolet: "",
        editToletData: editData
    },
    reducers: {
        messageClear: (state, _) => {
            state.successMessage = ""
            state.errorMessage = ""
        }
    },
    extraReducers: {
        [add_tolet.pending]: (state, _) => {
            state.loader = true
        },
        [add_tolet.rejected]: (state, _) => {
            state.loader = false
        },
        [add_tolet.fulfilled]: (state, _) => {
            state.successMessage = "Tolet add successfull"
            state.loader = false

        },
        [update_tolet.pending]: (state, _) => {
            state.loader = true
        },
        [update_tolet.rejected]: (state, action) => {
            state.loader = false
            state.errorMessage = action.payload.message
        },
        [update_tolet.fulfilled]: (state, action) => {
            state.successMessage = action.payload.message
            state.loader = false

        },

        [get_tolet.fulfilled]: (state, action) => {
            state.tolet = action.payload
        },
        [get_tolets.pending]: (state, action) => {
            state.loader = true
        },
        [get_tolets.fulfilled]: (state, action) => {
            state.loader = false
            state.tolets = action.payload
        },
        [add_image.fulfilled]: (state, action) => {
            state.successMessage = action.payload.message
            state.tolet = action.payload.tolet
        },
        [delete_image.fulfilled]: (state, action) => {
            state.successMessage = action.payload.message
            state.tolet = action.payload.tolet
        },

    }
})
export const { messageClear } = toletSlice.actions
export default toletSlice.reducer
