import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const get_mess_user = createAsyncThunk(
    'payment/get_mess_user',
    async () => {
        try {
            const { data: users } = await axios.get('http://localhost:5000/get-mess-user')
            return users
        } catch (error) {
            console.log(error)
        }
    }
)

export const get_payment = createAsyncThunk(
    'payment/get_payment',
    async () => {
        try {
            const { data: paymentUser } = await axios.get('http://localhost:5000/get-payment')
            return paymentUser
        } catch (error) {
            console.log(error)
        }
    }
)

export const create_payment = createAsyncThunk(
    'payment/create_payment',
    async (datas) => {
        try {
            const { data: message } = await axios.post('http://localhost:5000/create-payment', datas)
            console.log(message)
        } catch (error) {
            console.log(error)
        }
    }
)
export const add_member_bill = createAsyncThunk(
    'payment/add_member_bill',
    async ({ data, navigate }) => {
        try {
            await axios.post('http://localhost:5000/add-member-bill', data)
            navigate('/leader/members')
        } catch (error) {
            console.log(error)
        }
    }
)

export const update_member_bill = createAsyncThunk(
    'payment/add_member_bill',
    async ({ data, navigate }) => {
        try {
            await axios.post('http://localhost:5000/update-member-bill', data)
            navigate('/leader/payment-list')
        } catch (error) {
            console.log(error)
        }
    }
)

export const getSingleBill = createAsyncThunk(
    'payment/getSingleBill',
    async (billId) => {
        try {
            const { data: { bill } } = await axios.get(`http://localhost:5000/get-single-bill/${billId}`)
            return bill
        } catch (error) {
            console.log(error)
        }
    }
)
export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        successMessage: '',
        errorMessage: '',
        userData: [],
        paymentUser: [],
        editBillData: ""
    },
    reducers: {

    },
    extraReducers: {
        [get_mess_user.fulfilled]: (state, action) => {
            const { payload } = action
            return {
                ...state,
                userData: payload.users

            }
        },
        [get_payment.fulfilled]: (state, action) => {
            const { payload } = action
            return {
                ...state,
                paymentUser: payload.paymentUser
            }
        },
        [getSingleBill.fulfilled]: (state, action) => {
            return {
                ...state,
                editBillData: action.payload
            }
        }
    }
})
export default paymentSlice.reducer