import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/index';
import { Toaster } from "react-hot-toast";
//import Loader from './views/Loader'
import './index.css';
import reportWebVitals from './reportWebVitals';
const LazyApp = lazy(() => import('./App'))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback="">
      <LazyApp />
      <Toaster toastOptions={{
        position: 'top-right'
      }} />
    </Suspense>
  </Provider>
);
reportWebVitals();
