import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const chatReducer = createSlice({
    name: 'chat',
    initialState: {
        successMessage: '',
        addMessage: '',
        addError: '',
        errorMessage: '',
        allMessage: [],
        myFriend: [],
        messages: [],
        fdInfo: {},
        activeUser: [],
        receiveMessage: "",
        typingRes: ''
    },
    reducers: {
        clearSuccessMessage: (state, action) => {
            state.successMessage = ''
        },
        activeUserSet: (state, action) => {
            state.activeUser = action.payload
        },
        receiveMessageSet: (state, action) => {
            state.receiveMessage = action.payload
        },
        updateMessage: (state, action) => {
            state.messages = [...state.messages, action.payload]
        },
        typingMessageSet: (state, action) => {
            state.typingRes = action.payload
        }
    },
    extraReducers: {

    }
})
export const { clearSuccessMessage, activeUserSet, receiveMessageSet, updateMessage, typingMessageSet } = chatReducer.actions;
export default chatReducer.reducer
