import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (data) => {
        try {
            // const response = await axios.post('url', data)
            return data
        } catch (error) {

        }
    }
)
export const changePassword = createAsyncThunk(
    'profile/changePassword',
    async (data) => {
        try {
            // const response = await axios.post('url', data)
            //return data
            return {
                message: 'Password change success'
            }
        } catch (error) {

        }
    }
)


export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        successMessage: '',
        errorMessage: '',
        profileData: {
            name: 'Sheikh farid',
            mobile: '01345634675',
            email: 'farid@gmail.com'
        }
    },
    reducers: {
        clearMessage: (state, action) => {
            state.successMessage = ''
        }
    },
    extraReducers: {
        [updateProfile.fulfilled]: (state, action) => {
            state.profileData = action.payload
            state.successMessage = 'Update profile success'
        },
        [changePassword.fulfilled]: (state, action) => {
            state.successMessage = action.payload.message
        }
    }
})
export const { clearMessage } = profileSlice.actions
export default profileSlice.reducer